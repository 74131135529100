<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" md="7" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
              <b-form-group label="Default Currency" label-for="role">
                <v-select v-model="defaultCurrency" @input="updateDefaultCurrency" :options="currencies" :reduce="(val) => val.id" label="code" :clearable="false" input-id="role" />
              </b-form-group>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input v-model="filter" :clearable="true" class="d-inline-block mr-1" placeholder="Search..." />
                <b-button variant="primary" @click="addCurrency()">
                  <span class="text-nowrap">Add New Currency</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          class="position-relative"
          :items="currencies"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          responsive
          :small="true"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Actions -->
          <template #cell(actions)="row">
            <b-button size="sm" @click="updateCurrency(row.item)" class="mr-1">
              Edit Currency
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="9" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>

            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <b-modal id="modal-currency" cancel-variant="outline-secondary" ok-title="Save" @ok="handleUpdateCurrency" cancel-title="Close" :no-close-on-backdrop="true" centered title="Currency">
          <b-form ref="currencyRules">
            <b-form-group :state="currencyState" invalid-feedback="Please fill the required fields">
              <label for="currency">Code:</label>
              <b-form-input id="currency" type="text" :state="currencyState" v-model="modalCurrencyCode" required />
              <label for="symbol" class="mt-1">Symbol:</label>
              <b-form-input id="symbol" type="text" :state="currencyState" v-model="modalCurrencySymbol" required />
            </b-form-group>
          </b-form>
        </b-modal>

        <b-modal id="modal-currency-add" cancel-variant="outline-secondary" ok-title="Save" @ok="handleAddCurrency" cancel-title="Close" :no-close-on-backdrop="true" centered title="Currency">
          <b-form ref="currencyAddRules">
            <b-form-group :state="currencyState" invalid-feedback="Please fill the required fields">
              <label for="currency">Code:</label>
              <b-form-input id="currency" type="text" :state="currencyState" v-model="modalCurrencyCode" required />
              <label for="symbol" class="mt-1">Symbol:</label>
              <b-form-input id="symbol" type="text" :state="currencyState" v-model="modalCurrencySymbol" required />
            </b-form-group>
          </b-form>
        </b-modal>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import { BTable } from 'bootstrap-vue';
import store from '@/store';
import settingsStoreModule from '../settingsStoreModule';
import { onUnmounted } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BTabs, BTab, BCardText } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BTable,
    vSelect,

    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  data() {
    // { key: 'quoteDate', show: true, label: 'Quote Date', class: 'text-center', sortable: false, class: 'text-center', thStyle: 'width: 10%' },
    const tableColumns = [
      {
        key: 'id',
        show: true,
        sortable: true,
        class: 'text-center',
        thStyle: 'width: 10%',
      },
      {
        key: 'code',
        label: 'Currency Code',
        show: true,
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
      {
        key: 'symbol',
        label: 'Currency Symbol',
        show: true,
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
      {
        key: 'actions',
        show: true,
        sortable: false,
        class: 'text-center',
        thStyle: 'width: 30%',
      },
    ];

    return {
      rolePerms: [],
      edit: null,
      newitem: '',
      filter: null,
      filterOn: ['code', 'symbol', 'id'],
      perPage: 10,
      currencies: [],
      permissions: [],
      currentPage: 1,
      perPageOptions: [5, 10, 15, 20],
      tableColumns,
      totalRows: 0,
      loading: false,
      modalCurrencyCode: null,
      modalCurrencySymbol: null,
      modalCurrencyId: null,
      defaultCurrency: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        // from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        // to: perPage.value * (currentPage.value - 1) + localItemsCount,
        // of: totalUsers.value,
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
    currencyState() {
      return this.modalCurrencyCode != null && this.modalCurrencySymbol != null && this.modalCurrencyCode != '' && this.modalCurrencySymbol != '' ? true : false;
    },
  },
  methods: {
    getCurrencies() {
      this.loading = true;
      store
        .dispatch('settings/fetchCurrencies', [])
        .then((res) => {
          this.totalRows = res.data.data.length;
          this.currencies = res.data.data;
          this.defaultCurrency = res.data.defaultCurrency;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    updateDefaultCurrency() {
      this.formShow = true;

      var data = {
        id: this.defaultCurrency,
      };

      axiosIns
        .post('defaultCurrencyUpdate', data)
        .then((response) => {
          this.formShow = false;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Default currency has been updated',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    updateCurrency(val) {
      this.modalCurrencyId = val.id;
      this.modalCurrencyCode = val.code;
      this.modalCurrencySymbol = val.symbol;

      this.$bvModal.show('modal-currency');
    },

    addCurrency() {
      this.$bvModal.show('modal-currency-add');
    },

    handleUpdateCurrency(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdateCurrency();
    },

    handleAddCurrency(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalAddCurrency();
    },

    modalUpdateCurrency(val) {
      if (!this.checkFormValidityCurrency()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-currency');
      });

      this.formShow = true;

      var data = {
        id: this.modalCurrencyId,
        code: this.modalCurrencyCode,
        symbol: this.modalCurrencySymbol,
      };

      axiosIns
        .post('currencyUpdate', data)
        .then((response) => {
          this.modalCurrencyCode = null;
          this.modalCurrencySymbol = null;
          this.modelCurrencyId = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalAddCurrency(val) {
      if (!this.checkFormValidityCurrencyAdd()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-currency-add');
      });

      this.formShow = true;

      var data = {
        code: this.modalCurrencyCode,
        symbol: this.modalCurrencySymbol,
      };

      axiosIns
        .post('currencyAdd', data)
        .then((response) => {
          this.modalCurrencyCode = null;
          this.modalCurrencySymbol = null;
          this.modelCurrencyId = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidityCurrency() {
      const currencyValid = this.$refs.currencyRules.checkValidity();

      return currencyValid;
    },

    checkFormValidityCurrencyAdd() {
      const currencyValid = this.$refs.currencyAddRules.checkValidity();

      return currencyValid;
    },
  },
  created() {
    this.getCurrencies();
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
